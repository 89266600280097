var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        { staticClass: "table-page-search-wrapper" },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "用户名" } },
                        [
                          _c("j-input", {
                            attrs: { placeholder: "请输入名称模糊查询" },
                            model: {
                              value: _vm.queryParam.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParam, "name", $$v)
                              },
                              expression: "queryParam.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "年龄" } },
                        [
                          _c("a-input", {
                            staticStyle: { width: "calc(50% - 15px)" },
                            attrs: { placeholder: "最小年龄", type: "ge" },
                            model: {
                              value: _vm.queryParam.age_begin,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParam, "age_begin", $$v)
                              },
                              expression: "queryParam.age_begin",
                            },
                          }),
                          _c("span", { staticClass: "group-query-strig" }, [
                            _vm._v("~"),
                          ]),
                          _c("a-input", {
                            staticStyle: { width: "calc(50% - 15px)" },
                            attrs: { placeholder: "最大年龄", type: "le" },
                            model: {
                              value: _vm.queryParam.age_end,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParam, "age_end", $$v)
                              },
                              expression: "queryParam.age_end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.toggleSearchStatus
                    ? [
                        _c(
                          "a-col",
                          { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              { attrs: { label: "生日" } },
                              [
                                _c("a-range-picker", {
                                  attrs: {
                                    format: "YYYY-MM-DD",
                                    placeholder: ["开始时间", "结束时间"],
                                  },
                                  on: { change: _vm.onBirthdayChange },
                                  model: {
                                    value: _vm.queryParam.birthdayRange,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParam,
                                        "birthdayRange",
                                        $$v
                                      )
                                    },
                                    expression: "queryParam.birthdayRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              { attrs: { label: "性别" } },
                              [
                                _c("j-dict-select-tag", {
                                  attrs: {
                                    placeholder: "请选择性别",
                                    dictCode: "sex",
                                  },
                                  model: {
                                    value: _vm.queryParam.sex,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "sex", $$v)
                                    },
                                    expression: "queryParam.sex",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              { attrs: { label: "选择用户" } },
                              [
                                _c("j-dict-select-tag", {
                                  attrs: {
                                    placeholder: "请选择用户",
                                    dictCode: "demo,name,id",
                                  },
                                  model: {
                                    value: _vm.queryParam.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "id", $$v)
                                    },
                                    expression: "queryParam.id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "table-page-search-submitButtons",
                      staticStyle: { float: "left", overflow: "hidden" },
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "search" },
                              on: { click: _vm.searchQuery },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "8px" },
                              attrs: { type: "primary", icon: "reload" },
                              on: { click: _vm.searchReset },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "8px" },
                              on: { click: _vm.handleToggleSearch },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.toggleSearchStatus ? "收起" : "展开"
                                  ) +
                                  "\n              "
                              ),
                              _c("a-icon", {
                                attrs: {
                                  type: _vm.toggleSearchStatus ? "up" : "down",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-operator" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.jump },
            },
            [_vm._v("创建单据")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.onetomany },
            },
            [_vm._v("一对多")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "download" },
              on: {
                click: function ($event) {
                  return _vm.handleExportXls("单表示例")
                },
              },
            },
            [_vm._v("导出")]
          ),
          _c(
            "a-upload",
            {
              attrs: {
                name: "file",
                showUploadList: false,
                multiple: false,
                headers: _vm.tokenHeader,
                action: _vm.importExcelUrl,
              },
              on: { change: _vm.handleImportExcel },
            },
            [
              _c("a-button", { attrs: { type: "primary", icon: "import" } }, [
                _vm._v("导入"),
              ]),
            ],
            1
          ),
          _c("j-super-query", {
            ref: "superQueryModal",
            attrs: { fieldList: _vm.fieldList },
            on: { handleSuperQuery: _vm.handleSuperQuery },
          }),
          _vm.selectedRowKeys.length > 0
            ? _c(
                "a-dropdown",
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        { key: "1", on: { click: _vm.batchDel } },
                        [
                          _c("a-icon", { attrs: { type: "delete" } }),
                          _vm._v("\n          删除\n        "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    { staticStyle: { "margin-left": "8px" } },
                    [
                      _vm._v(" 批量操作\n        "),
                      _c("a-icon", { attrs: { type: "down" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "ant-alert ant-alert-info",
              staticStyle: { "margin-bottom": "16px" },
            },
            [
              _c("i", {
                staticClass: "anticon anticon-info-circle ant-alert-icon",
              }),
              _vm._v(" 已选择 "),
              _c("a", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(_vm._s(_vm.selectedRowKeys.length)),
              ]),
              _vm._v("项\n      "),
              _c(
                "a",
                {
                  staticStyle: { "margin-left": "24px" },
                  on: { click: _vm.onClearSelected },
                },
                [_vm._v("清空")]
              ),
              _c(
                "span",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.loadData()
                        },
                      },
                    },
                    [_c("a-icon", { attrs: { type: "sync" } }), _vm._v("刷新")],
                    1
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        title: "自定义列",
                        trigger: "click",
                        placement: "leftBottom",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "content" },
                        [
                          _c(
                            "a-checkbox-group",
                            {
                              attrs: { defaultValue: _vm.settingColumns },
                              on: { change: _vm.onColSettingsChange },
                              model: {
                                value: _vm.settingColumns,
                                callback: function ($$v) {
                                  _vm.settingColumns = $$v
                                },
                                expression: "settingColumns",
                              },
                            },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { width: "400px" } },
                                [
                                  _vm._l(
                                    _vm.defColumns,
                                    function (item, index) {
                                      return [
                                        item.key != "rowIndex" &&
                                        item.dataIndex != "action"
                                          ? [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "a-checkbox",
                                                    {
                                                      attrs: {
                                                        value: item.dataIndex,
                                                      },
                                                    },
                                                    [
                                                      _c("j-ellipsis", {
                                                        attrs: {
                                                          value: item.title,
                                                          length: 10,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        [
                          _c("a-icon", { attrs: { type: "setting" } }),
                          _vm._v("设置"),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-table",
            {
              ref: "table",
              attrs: {
                size: "middle",
                bordered: "",
                rowKey: "id",
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                pagination: _vm.ipagination,
                loading: _vm.loading,
                rowSelection: {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange,
                },
              },
              on: { change: _vm.handleTableChange },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function (text, record) {
                    return _c(
                      "span",
                      {},
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-dropdown",
                          [
                            _c(
                              "a",
                              { staticClass: "ant-dropdown-link" },
                              [
                                _vm._v("更多 "),
                                _c("a-icon", { attrs: { type: "down" } }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: { title: "确定删除吗?" },
                                        on: {
                                          confirm: () =>
                                            _vm.handleDelete(record.id),
                                        },
                                      },
                                      [_c("a", [_vm._v("删除")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                { attrs: { slot: "filterDropdown" }, slot: "filterDropdown" },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "a-checkbox-group",
                        {
                          attrs: { defaultValue: _vm.settingColumns },
                          on: { change: _vm.onColSettingsChange },
                          model: {
                            value: _vm.settingColumns,
                            callback: function ($$v) {
                              _vm.settingColumns = $$v
                            },
                            expression: "settingColumns",
                          },
                        },
                        [
                          _c(
                            "a-row",
                            { staticStyle: { width: "400px" } },
                            [
                              _vm._l(_vm.defColumns, function (item, index) {
                                return [
                                  item.key != "rowIndex" &&
                                  item.dataIndex != "action"
                                    ? [
                                        _c(
                                          "a-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "a-checkbox",
                                              {
                                                attrs: {
                                                  value: item.dataIndex,
                                                },
                                              },
                                              [
                                                _c("j-ellipsis", {
                                                  attrs: {
                                                    value: item.title,
                                                    length: 10,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-icon", {
                style: { fontSize: "16px", color: "#108ee9" },
                attrs: { slot: "filterIcon", type: "setting" },
                slot: "filterIcon",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jeecgDemo-modal", { ref: "modalForm", on: { ok: _vm.modalFormOk } }),
      _c("JeecgDemoTabsModal", {
        ref: "jeecgDemoTabsModal",
        on: { ok: _vm.modalFormOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }